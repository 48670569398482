@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Sailec;
  font-weight: 700;
  src: url("../public/fonts/Sailec Bold.ttf");
}

@font-face {
  font-family: Sailec;
  font-weight: 500;
  src: url("../public/fonts/Sailec Medium.ttf");
}

@font-face {
  font-family: Cooper;
  font-weight: 700;
  src: url("../public/fonts/Cooper Std Black.ttf");
}

@font-face {
  font-family: Sailec;
  font-weight: 400;
  src: url("../public/fonts/Sailec Light.ttf");
}

button:hover {
  opacity: 0.8;
  transition: 0.3s ease-in-out;
}

body {
  font-family: Sailec, sans-serif;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #444648;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.hover-fin-child {
  display: none;
}

.hover-parent:hover .hover-fin-child {
  display: flex;
}

.hover-parent:hover .hover-text {
  color: #EE383A;
}

.hover-parent:hover .hover-child {
  display: none;
}

.review-card__anim1 {
  animation: reviewCardAnim1 35s ease-in-out infinite alternate;
}

.review-card__anim1:hover {
  animation-play-state: paused;
}

@keyframes reviewCardAnim1 {
  from {
    transform: translateX(-80%);
  }
  to {
    transform: translateX(0%);
  }
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #444648;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}


.full_width {
  width: 100vw;
  margin-left: calc((100% - 100vw) / 2);
  margin-right: calc((100% - 100vw) / 2);
}
